import { apiClient } from '~/services/api-client';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { DefaultFetchOptions } from '../types';
import { Users } from '~/models/users';

type Args = DefaultFetchOptions;

export default function getActiveUser({ locale, user, signal }: Args): Promise<Users.IBasicUserInfo> {
    const url = createUrl(
        {
            endpoint: endpoints.users.meInfo,
            localeOption: LocaleOptions.omit,
        },
        locale,
    );
    return apiClient.auth(user).get(url, {
        signal,
    });
}
