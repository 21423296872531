import { createQueryKeys } from '@lukemorales/query-key-factory';
import getActiveUser from '~/services/user/getActiveUser';

export const usersKeys = createQueryKeys('users', {
    getActiveUser: (args: Parameters<typeof getActiveUser>[0], isImpersonated: boolean) => ({ ...args }),
    departmentsByRegion: (locale?: string, activeProfileId?: string) => [
        {
            locale,
            activeProfileId,
        },
    ],
    externalLogin: (locale?: string) => [locale],
    manualWebOrderCount: (locale?: string, departmentId?: number) => [locale, departmentId],
    invoiceDetails: (locale?: string, departmentId?: number, id?: string | number) => [
        {
            locale,
            departmentId,
            id,
        },
    ],
    previousOrders: (locale?: string, ftzCode?: string, departmentId?: number) => [
        {
            locale,
            ftzCode,
            departmentId,
        },
    ],
    subUsers: (locale?: string, userId?: number, query?: string) => [
        {
            locale,
            userId,
            query,
        },
    ],
    findUserByQuery: (locale?: string, query?: string, limit?: number, take?: number) => [
        {
            locale,
            query,
            limit,
            take,
        },
    ],
    simpleVariant: (locale?: string, activeProfileId?: string, ftzCode?: string, includeExpertProducts?: boolean) => [
        {
            locale,
            activeProfileId,
            ftzCode,
            includeExpertProducts,
        },
    ],
    simpleVariantsByFtz: (locale?: string, variantIds?: string[], includeExpertProducts?: boolean | null) => [
        {
            locale,
            variantIds,
            includeExpertProducts,
        },
    ],
    useLatestCustomers: (limit?: number) => [limit],
    migrateUser: (locale?: string) => [
        {
            locale,
        },
    ],
    manualWebOrders: (locale?: string, department?: string, customerId?: string, sortBy?: string, sortDir?: string) => [
        {
            locale,
            department,
            customerId,
            sortBy,
            sortDir,
        },
    ],
});
