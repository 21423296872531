import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { useLayoutState } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { GroupedVehicleOilInfo } from '~/libs/queries/vehicles';
import { apiClient } from '~/services/api-client';

export type UseVehicleOilInfoProps = {
    ftzCodeList?: string[];
    shouldFetch?: boolean;
    select: (data: GroupedVehicleOilInfo) => GroupedVehicleOilInfo;
};

export function useVehicleOilInfo({ ftzCodeList, shouldFetch, select }: UseVehicleOilInfoProps) {
    const { activeProfile } = useActiveUser();
    const { vehicle } = useLayoutState();
    const { isLoggedIn, user } = useUser();
    const { products, createUrl } = useEndpoints();

    const endpoint = createUrl({
        endpoint: `${products.vehicles}/${vehicle?.carId}/oil-data-with-model`,
        localeOption: LocaleOptions.path,
        query: {
            licensePlate: vehicle?.licensePlate?.number,
            licensePlateCountry: vehicle?.licensePlate?.country,
        },
    });

    const enabled = shouldFetch && ftzCodeList && ftzCodeList.length > 0 && isLoggedIn && !!vehicle;

    return useQuery<GroupedVehicleOilInfo, Error>({
        queryKey: queryKeys.vehicle.oilInfo(activeProfile?.id, ftzCodeList),
        queryFn: () => apiClient.auth(user).get(endpoint),
        enabled: !!enabled,
        select,
    });
}
