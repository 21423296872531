import { UseMutationOptions } from '@tanstack/react-query/src/types';
import { useRouter } from 'next/router';
import { useMutation } from '@tanstack/react-query';
import { apiClient } from '~/services/api-client';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';

import type { Orders as OrderTypes } from '~/models/orders';

export type UseCreateOECreditMutateArgs = { invoiceId: number; data: OrderTypes.ICreditOeInvoiceRequest };

export function useCreateOECredit(opts: UseMutationOptions<OrderTypes.ICreditOeInvoiceResponse, unknown, UseCreateOECreditMutateArgs> = {}) {
    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation<OrderTypes.ICreditOeInvoiceResponse, unknown, UseCreateOECreditMutateArgs>({
        mutationFn: ({ invoiceId, data }) => {
            return apiClient.auth(user).post(
                createUrl(
                    {
                        endpoint: `${endpoints.orders.credits}/${invoiceId}/credit/oe`,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                {
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
        },
        ...opts,
    });
}
