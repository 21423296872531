import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { ORDERS_PREVIOUS_BY_IDENTIFIER } from '~/libs/queries/orders/constants';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { Bff } from '~/models/bff';
import { Session } from 'next-auth';
import { useLayoutState } from '~/context/layout.context';

export type GetPreviousOrderByIdentifierProps = {
    identifier?: string;
    locale?: string;
    user?: Session | null;
    departmentId?: number;
    signal?: AbortSignal;
};

export function getPreviousOrdersByIdentifier({ identifier, departmentId, locale, user }: GetPreviousOrderByIdentifierProps) {
    return apiClient.auth(user).get(
        createUrl(
            {
                endpoint: ORDERS_PREVIOUS_BY_IDENTIFIER,
                localeOption: LocaleOptions.query,
                query: {
                    identifier,
                    departmentId,
                },
            },
            locale,
        ),
    );
}

export function usePreviousOrdersByIdentifier(identifier: string, config?: UseQueryOptions<Bff.IVariantSimpleWithInvoices>) {
    const { locale } = useRouter();
    const { selectedDepartment } = useLayoutState();
    const { user } = useUser();

    return useQuery<Bff.IVariantSimpleWithInvoices>({
        queryKey: queryKeys.orders.previousOrdersByIdentifier(locale, selectedDepartment?.id, identifier),
        queryFn: ({ signal }) => {
            return getPreviousOrdersByIdentifier({
                locale,
                departmentId: selectedDepartment?.id,
                identifier,
                user,
                signal,
            });
        },
        ...config,
    });
}
