import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '~/services/api-client';
import { Orders } from '~/models/orders';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { UseMutationOptions } from '@tanstack/react-query/src/types';
import useUser from '~/libs/use-user';
import { Session } from 'next-auth';
import { queryKeys } from '~/libs/queries/query-keys';
import { useRouter } from 'next/router';

export type BatchCreateCreditParams = {
    user?: Session | null;
    locale?: string;
    payload: Orders.ICreditInvoiceRequest;
};

export function batchCreateCredit({ user, payload, locale }: BatchCreateCreditParams) {
    return apiClient.auth(user).post(
        createUrl(
            {
                endpoint: `${endpoints.orders.credits}/credit-multiple`,
                localeOption: LocaleOptions.path,
            },
            locale,
        ),
        {
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
}

export function useBatchCreateCredit(opts: UseMutationOptions<Orders.ICreateCreditResponse, unknown, Orders.ICreditInvoiceRequest> = {}) {
    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation<Orders.ICreateCreditResponse, unknown, Orders.ICreditInvoiceRequest>({
        mutationFn: (payload) => {
            return batchCreateCredit({ user, payload, locale });
        },
        ...opts,
    });
}

export type UseBatchCreateCreditValidationQueryOptions = UseQueryOptions<Orders.ICreateCreditResponse, unknown>;

export type UseBatchCreateCreditValidationProps = {
    items: Orders.ICreditItemRequest[];
    options?: UseBatchCreateCreditValidationQueryOptions;
};

export function useBatchCreateCreditValidationQuery({ items, options }: UseBatchCreateCreditValidationProps) {
    const { locale } = useRouter();
    const { user } = useUser();

    return useQuery<Orders.ICreateCreditResponse, unknown>({
        queryKey: queryKeys.orders.batchCreateCreditDryRun(locale, user?.profile?.user_id, items),
        enabled: user?.isLoggedIn && items.length > 0,
        queryFn: () => {
            return batchCreateCredit({
                user,
                payload: {
                    items,
                    // We do not create the actual credit. We just validate the items.
                    isDryRun: true,
                },
            });
        },
        ...options,
    });
}
