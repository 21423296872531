import { useMutation } from '@tanstack/react-query';
import { Products } from '~/models/products';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { useRouter } from 'next/router';
import { getFormData } from '~/helpers';

export type UseRequestOeProductParams = {
    OeType: `${Products.OeType}`;
    Subject: string;
    Files: File[];

    Contact: string;
    Email: string;
    CustomerNumber: string;
    CompanyName: string;
    Address: string;
    Department: string;
    PhoneNumber: string;

    RegNr: string;
    EngineCode: string;
    Vin: string;
    ModelName: string;
    FirstRegistration: string;
    LastInspection: string;
    ModelTypeName: string;
    ModelTypeId: string;
    ManufacturerName: string;
    IncludeUserInCc: boolean;

    ErrorCodes: string;
    Description: string;
};

export function useRequestOeProduct() {
    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation<Products.ISubmitOeSparePartsResult, unknown, UseRequestOeProductParams>({
        mutationFn: ({ Files, ...data }) => {
            const body = getFormData(data);

            Files.forEach((file) => body.append('Files', file));

            return apiClient.auth(user).post(
                createUrl(
                    {
                        endpoint: endpoints.products.oeRequestPost,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                {
                    body,
                },
            );
        },
    });
}
