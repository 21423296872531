import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';
import { queryKeys } from '../../query-keys';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { ORDERS_PREVIOUS_ORDER_URL } from '../constants';
import { apiClient } from '~/services/api-client';
import { Orders as OrderTypes } from '~/models/orders';
import { Session } from 'next-auth';

export type GetPreviousOrderByItemNoArgs = {
    ftzCode?: string;
    locale?: string;
    user?: Session | null;
};

export function getPreviousOrdersByItemNo({ ftzCode, locale, user }: GetPreviousOrderByItemNoArgs) {
    return apiClient.auth(user).get(
        createUrl(
            {
                endpoint: ORDERS_PREVIOUS_ORDER_URL,
                localeOption: LocaleOptions.path,
                query: {
                    ftzCode,
                },
            },
            locale,
        ),
    );
}

export function usePreviousOrders(ftzCode?: string, config?: UseQueryOptions<OrderTypes.IVariantInvoicesList, Error>) {
    const { user, isLoggedIn } = useUser();
    const { locale } = useRouter();

    return useQuery<OrderTypes.IVariantInvoicesList, Error>({
        queryKey: queryKeys.orders.previous(locale, ftzCode),
        staleTime: 0,
        queryFn: () => getPreviousOrdersByItemNo({ ftzCode, locale, user }),
        enabled: isLoggedIn && !!ftzCode?.length,
        ...config,
    });
}
